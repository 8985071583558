import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";

export class OpeningBalanceService{
    async getOpeningBalances(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.openingBalance.openingBalance, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, openingBalances: response.data.opening_balances};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', openingBalances: []};
            });
    }
    async saveOpeningBalance(openingBalances){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.openingBalance.openingBalanceSave, openingBalances).then(async (response) => {
            return {isSuccess: response.data.status === 'success', message: response.data.msg};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!'};
        });
    }

}