<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <chart-of-accounts></chart-of-accounts>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import ChartOfAccounts from "@/components/app/account/ChartOfAccounts";

    export default {
        name: 'ChartOfAccounts_view',
        data: function(){
            return {
                pageName: 'Chart Of Accounts',
                segments: [
                    { link: false, text: 'Chart of Accounts' },
                ],
                id: 0
            }
        },
        props: {},
        components: {
            ChartOfAccounts,
            BreadCrumb
        },
        computed: {

        },
        methods:{

        },
        created() {

        }
    }
</script>

<style scoped>

</style>
