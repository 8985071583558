<template>
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:tableSize="tableSize" ref="mainDataTable" v-if="showList"></CustomDataTable>
            <supplier-category v-if="!showList" :save-handler="saveHandler" :id="singleId"></supplier-category>
        </section>
</template>

<script>
    import Vue from 'vue';
    import CustomDataTable from "../table/CustomDataTable";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {SupplierService} from "@/services/supplier-service";
    import SupplierCategory from "@/components/app/supplier/SupplierCategory";
    import {getPermissionByKey} from "@/helpers/userHelper";
    let supplierService = new SupplierService();
    export default {
        name: 'SupplierCategories',
        data: function(){
            return {
                pageName: 'Supplier Categories',
                segments: [
                    { link: true, text: 'Suppliers', routeName: 'App.Suppliers' },
                    { link: false, text: 'Categories' },
                ],
                tableSize: 'col-sm-12',
                account: {},
                tableDataUrl: this.$globalSettings.api.endpoints.supplierCategory.supplierCategoriesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                    {label: 'Name', name: 'name', orderable: true},
                    {
                        label: '',
                        style: 'width: 10px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.supplierCategory.supplierCategory_edit}],
                                    conditions: [],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.supplierCategory.supplierCategory_delete}],
                                    conditions: [],
                                },
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],

                showList: false,
                singleId: 0,
            }
        },
        props: {
            mode:{
                type: String,
                default: () => 'list'
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },

        },
        components: {
            SupplierCategory, CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                this.showList = false;
                this.singleId = 0;
            },
            edit(data){
                this.showList = false;
                this.singleId = data.id;
            },
            saveHandler: async function () {
                this.showList = true;
                this.singleId = 0;
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this account?")) {
                    let loader = this.$loading.show();
                    let response = await supplierService.deleteSupplierCategory(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            }
        },
        mounted() {
            this.showList = this.mode === 'list';
            if(this.showAddButtons) {
                if (getPermissionByKey(this.$globalSettings.permissions.supplierCategory.supplierCategory_create)) {
                    this.additionalButtons.push({ label: 'Add', buttonClass: 'btn btn-outline-secondary btn-sm float-right', labelIconClass: 'fas fa-plus', click: this.add })
                }
            }
        }
    }
</script>

<style scoped>

</style>
