<template>
    <ModalGeneral :title="title" size="modal-lg" :showModal="showModal" :hideModal="hideModal" v-if="showModal">
        <div slot="modal-body">
            <div>
                <div class="row text-bold border-bottom pb-2"><div class="col-sm-6">Store</div><div class="col-sm-6">Opening Balance</div></div>
                <ValidationObserver v-slot="{ invalid }">
                    <form @submit="saveForm">
                        <div class="row border-bottom pt-2 pb-2" v-for="opening_balance in opening_balances" :key="opening_balance.id">
                            <div class="col-sm-6">
                                {{ opening_balance.store_name }}
                            </div>
                            <div class="col-sm-6">
                                <ValidationProvider v-slot="{ errors }" rules="required|realNumber">
                                    <input type="text" class="form-control form-control-sm" v-model="opening_balance.balance" />
                                    <span>{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-sm float-right mt-3" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </ModalGeneral>
</template>

<script>
    import Vue from 'vue'
    import ModalGeneral from "../dialog/ModalGeneral";


    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('realNumber', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return regexFloatingPoint.test(value)? true: 'This field must be a number';
    });
    import {OpeningBalanceService} from "@/services/ob-service";
    let openingBalanceService = new OpeningBalanceService();
    export default {
        name: "ModalOpeningBalance",
        components: {ModalGeneral, ValidationObserver, ValidationProvider},
        data: function() {
            return {
                opening_balances: [],
            }
        },
        props: {
            entity_type:{
                type: Number,
                default: () => null
            },
            entity_id:{
                type: Number,
                default: () => null
            },
            title:  {
                type: String,
                default: () => ''
            },
            showModal:  {
                type: Boolean,
                default: () => false
            },
            hideModal: {
                type: Function,
                default:() => {}
            },
        },
        methods: {
            async loadSavedData() {
                let loader = this.$loading.show();
                let response = await openingBalanceService.getOpeningBalances({
                    entity_type: this.entity_type,
                    entity_id: this.entity_id,
                });
                if (response.isSuccess) {
                    this.opening_balances = response.openingBalances;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();

                let response = await openingBalanceService.saveOpeningBalance({
                    opening_balances: this.opening_balances
                });
                if (response.isSuccess) {
                    this.hideModal();
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
        },
        created() {
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>