<template>
    <section class="content pl-2 pr-2">
        <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additional-buttons="additionalButtons" ref="mainDataTable"></CustomDataTable>

        <ModalOpeningBalance :title="openingBalancesModal.title" size="modal-lg" :showModal="openingBalancesModal.showModal" :hideModal="hideOpeningBalancesModal" :entity_type="openingBalancesModal.entity_type" :entity_id="openingBalancesModal.entity_id" v-if="openingBalancesModal.showModal"></ModalOpeningBalance>

        <ModalGeneral :title="customerCategoriesModal.title" size="modal-lg" :showModal="customerCategoriesModal.showModal" :hideModal="hideCustomerCategoriesModal" v-if="customerCategoriesModal.showModal">
            <div slot="modal-body">
                <customer-categories :mode="customerCategoriesModal.mode"></customer-categories>
            </div>
        </ModalGeneral>

        <ModalGeneral :title="ledgerModal.title" size="modal-lg" :showModal="ledgerModal.showModal" :hideModal="hideLedgerModal" v-if="ledgerModal.showModal">
            <div slot="modal-body">
                <report-entity-ledger type="customer" v-bind:report-name="ledgerModal.title" v-bind:entity_id="ledgerModal.customer_id" :generate-on-load="true" :disable-entity-selection="true"></report-entity-ledger>
            </div>
        </ModalGeneral>
    </section>
</template>

<script>

import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
import Vue from "vue";
import {getPermissionByKey} from "@/helpers/userHelper";
import {CustomerService} from "@/services/customer-service";
import CustomDataTable from "@/components/app/table/CustomDataTable";
import ModalOpeningBalance from "@/components/app/openingBalance/ModalOpeningBalance";
import ModalGeneral from "@/components/app/dialog/ModalGeneral";
import CustomerCategories from "@/components/app/customer/CustomerCategories";
import ReportEntityLedger from "@/components/app/report/ReportEntityLedger";
let customerService = new CustomerService();
export default {
    name: "Customers",
    components: {ReportEntityLedger, CustomerCategories, ModalGeneral, ModalOpeningBalance, CustomDataTable},
    data: function() {
        return {
            customer: {},
            tableDataUrl: this.$globalSettings.api.endpoints.customer.customersDatatable,
            columns: [
                {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                {label: 'Category', name: 'customer_category_name', orderable: true},
                {label: 'Name', name: 'name', orderable: true},
                {label: 'Image', name: 'image', orderable: false, transform: ({data, name}) => data[name]? `<img src="${this.$apiBaseURL + '/' + data[name]}" class="img-sm" />`: '' },
                {label: 'Address', name: 'address', orderable: true},
                {label: 'Email', name: 'email', orderable: true}
            ],
            searchFilters:{
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc'
            },
            additionalButtons: [],

            openingBalancesModal: {
                title: '',
                entity_type: null,
                entity_id: null,
                showModal: false,
            },
            customerCategoriesModal: {
                title: 'Customer Categories',
                showModal: false,
                mode: 'list',
            },
            ledgerModal: {
                title: 'Customer Ledger',
                showModal: false,
                customer_id: null,
            }
        }
    },
    props: {
        showActionButtons:{
            type: Boolean,
            default: () => true
        },
        showAdditionalButtons:{
            type: Boolean,
            default: () => true
        },
    },
    methods: {
        add(){
            this.$router.push({name: 'App.Customer', params: { id: 0 }});
        },
        edit(data){
            this.$router.push({name: 'App.Customer', params: { id: data.id }});
        },
        async delete(data) {
            if (confirm("Are you sure that you want to delete this customer?")) {
                let loader = this.$loading.show();

                let deleted = await customerService.deleteCustomer(data.id);
                if (deleted.isSuccess) {
                    Vue.$toast.open({message: deleted.message, type: 'success'});
                    this.$refs.mainDataTable.loadTable();
                } else {
                    Vue.$toast.open({message: deleted.message, type: 'error'});
                }
                loader.hide();
            }
        },
        categories(){
            this.customerCategoriesModal.showModal = true;
        },
        hideCustomerCategoriesModal(){
            this.customerCategoriesModal.showModal = false;
        },

        showOpeningBalancesModal(data){
            this.openingBalancesModal.title = data.name + ' Opening Balance';
            this.openingBalancesModal.entity_type = this.$globalEnums.obEntityTypes.Customer;
            this.openingBalancesModal.entity_id = data.id;
            this.openingBalancesModal.showModal = true;
        },
        hideOpeningBalancesModal(){
            this.openingBalancesModal.showModal = false;
        },

        showLedgerModal(data){
            this.ledgerModal.showModal = true;
            this.ledgerModal.customer_id = data.id;
        },
        hideLedgerModal(){
            this.ledgerModal.showModal = false;
        },
    },
    mounted() {
        if(this.showActionButtons){
            this.columns.push(
                {
                    label: '',
                    style: 'width: 60px',
                    component: CustomDataTableDropDownButton,
                    meta: {
                        items: [
                            {
                                label: 'Edit',
                                showLabel: true,
                                iconClass: 'fas fa-edit',
                                event: "click",
                                handler: this.edit,
                                dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.customer.customer_edit}],
                                conditions: [],
                            },
                            {
                                label: 'Opening Balances',
                                showLabel: true,
                                iconClass: 'fas fa-box-open',
                                event: "click",
                                handler: this.showOpeningBalancesModal,
                                dataPermissionConditions: [],
                                conditions: [this.$globalSettings.permissions.customer.customer_edit],
                            },
                            {
                                label: 'Ledger',
                                showLabel: true,
                                iconClass: 'fas fa-chart-line',
                                event: "click",
                                handler: this.showLedgerModal,
                                dataPermissionConditions: [],
                                conditions: [this.$globalSettings.permissions.report.report_customer_ledger],
                            },
                            {
                                label: 'Delete',
                                showLabel: true,
                                iconClass: 'fas fa-trash',
                                event: "click",
                                handler: this.delete,
                                dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.customer.customer_delete}],
                                conditions: [],
                            },
                        ]
                    }
                }
            );
        }
        if(this.showAdditionalButtons) {
            if (getPermissionByKey(this.$globalSettings.permissions.customerCategory.customerCategory_list)) {
                this.additionalButtons.push({
                    label: 'Categories',
                    buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                    labelIconClass: 'fas fa-sitemap',
                    click: this.categories
                })
            }
            if (getPermissionByKey(this.$globalSettings.permissions.customer.customer_create)) {
                this.additionalButtons.push({
                    label: 'Add',
                    buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                    labelIconClass: 'fas fa-plus',
                    click: this.add
                });
            }
        }
    }
}
</script>

<style scoped>

</style>