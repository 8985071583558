<template>
    <section class="content pl-2 pr-2">
        <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:tableSize="tableSize" ref="mainDataTable" v-if="showList"></CustomDataTable>
        <customer-category v-if="!showList" :save-handler="saveHandler" :id="singleId"></customer-category>
    </section>
</template>

<script>
    import Vue from 'vue';
    import CustomDataTable from "../table/CustomDataTable";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import {CustomerService} from "@/services/customer-service";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import CustomerCategory from "@/components/app/customer/CustomerCategory";

    let customerService = new CustomerService();
    export default {
        name: 'CustomerCategories',
        data: function(){
            return {
                pageName: 'Customer Categories',
                tableSize: 'col-sm-12',
                account: {},
                tableDataUrl: this.$globalSettings.api.endpoints.customerCategory.customerCategoriesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                    {label: 'Name', name: 'name', orderable: true},
                    {
                        label: '',
                        style: 'width: 60px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.customerCategory.customerCategory_edit}],
                                    conditions: [],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.customerCategory.customerCategory_delete}],
                                    conditions: [],
                                },
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],

                showList: false,
                singleId: 0,
            }
        },
        props: {
            mode:{
                type: String,
                default: () => 'list'
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            CustomerCategory,
            CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                this.showList = false;
                this.singleId = 0;
            },
            edit(data){
                this.showList = false;
                this.singleId = data.id;
            },
            saveHandler: async function () {
                this.showList = true;
                this.singleId = 0;
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this customer category?")) {
                    let loader = this.$loading.show();

                    let deleted = await customerService.deleteCustomerCategory(data.id);
                    if (deleted.isSuccess) {
                        Vue.$toast.open({message: deleted.message, type: 'success'});
                        await this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: deleted.message, type: 'error'});
                    }
                    loader.hide();
                }
            }
        },
        mounted() {
            this.showList = this.mode === 'list';
            if(this.showAddButtons) {
                if (getPermissionByKey(this.$globalSettings.permissions.customerCategory.customerCategory_create)) {
                    this.additionalButtons.push({
                        label: 'Add',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.add
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
