<template>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card card-secondary">
                            <div class="card-header">{{ formTitle }}</div>
                            <ValidationObserver v-slot="{ invalid }">
                                <form @submit="saveForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-sm-4">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label>Category *</label>
                                                    <select class="form-control" v-model="formData.ca_three.ca_two.ca_one_id" required>
                                                        <option v-for="caOne in caOnes" :key="caOne.id" :value="caOne.id">{{ caOne.name }}</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-4">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label>Sub Category *</label>
                                                    <select class="form-control" v-model="formData.ca_three.ca_two_id" :disabled="!formData.ca_three.ca_two.ca_one_id" required>
                                                        <option v-for="caTwo in filteredCaTwos" :key="caTwo.id" :value="caTwo.id">{{ caTwo.name }}</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-4">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="ca_three_id">Type *</label>
                                                    <select class="form-control" id="ca_three_id" v-model="formData.ca_three_id" :disabled="!formData.ca_three.ca_two_id" required>
                                                        <option v-for="caThree in filteredCaThrees" :key="caThree.id" :value="caThree.id">{{ caThree.name }}</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="name">Name *</label>
                                                    <input type="text" class="form-control" id="name" placeholder="Enter your name" v-model="formData.name" required>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <div class="row">
                                                    <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                                        <label for="upload_image">Image</label>
                                                        <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                                    </div>
                                                    <div class="col-sm-6" v-if="formData.image">
                                                        <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                                        <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="saveHandler()">Cancel</button>
                                        <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div><!--/. container-fluid -->
        </section>
</template>

<script>
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('realNumber', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return regexFloatingPoint.test(value)? true: 'This field must be a number';
    });
    import {AccountService} from "@/services/account-service";
    let accountService = new AccountService();

    export default {
        name: 'Account',
        data: function(){
            return {
                pageName: 'Account',
                segments: [
                    { link: true, text: 'Accounts', routeName: 'App.Accounts' },
                    { link: false, text: 'Account' },
                ],
                caOnes: [],
                caTwos: [],
                caThrees: [],
                upload_image: null,
                formData: {
                    id: 0,
                    ca_three_id: null,
                    name: null,
                    opening_balance: 0,
                    image: null,
                    ca_three: {
                        id: null,
                        ca_two_id: null,
                        ca_two:{
                            id: null,
                            ca_one_id: null,
                            ca_one: {
                                id: null
                            }
                        }
                    }
                },
                formTitle: ''
            }
        },
        props: {
            id:{
                type: Number,
                default: () => 0
            },
            ca_three_id:{
                type: Number,
                default: () => null
            },
            saveHandler: {
                type: Function,
                default: function () {}
            }
        },
        components: {
            ValidationObserver, ValidationProvider
        },
        computed: {
            filteredCaTwos: function (){
                return this.caTwos.filter( x => x.ca_one_id === this.formData.ca_three.ca_two.ca_one_id);
            },
            filteredCaThrees: function (){
                return this.caThrees.filter( x => x.ca_two_id === this.formData.ca_three.ca_two_id);
            },
        },
        methods:{
            loadCaOnes: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await accountService.loadCaOnes();
                this.caOnes = response.caOnes;
                loader.hide();
            },
            loadCaTwos: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await accountService.loadCaTwos();
                this.caTwos = response.caTwos;
                loader.hide();
            },
            loadCaThrees: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await accountService.loadCaThrees();
                this.caThrees = response.caThrees;
                loader.hide();
            },
            async loadSavedData() {
                if (this.id !== 0) {
                    this.formData.id = this.id;
                    let loader = this.$loading.show();

                    let response = await accountService.getAccount(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.account;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.saveHandler();
                    }
                    loader.hide();
                }
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                accountService.formDataAccount = this.formData;
                let response = await accountService.saveAccount(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.saveHandler();
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            }
        },
        created() {

        },
        async mounted() {
            this.formTitle = 'Update ';
            if(this.id === 0){
                this.formTitle = 'Add ';
                this.formData.ca_three_id = this.ca_three_id;
                this.formData.name = '';
            }
            this.formTitle += this.pageName;

            await this.loadCaOnes();
            await this.loadCaTwos();
            await this.loadCaThrees();
            await this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
