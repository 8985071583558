<template>
    <section class="content pl-2 pr-2">
        <div class="row">
            <div class="col-sm-3 tree" v-for="(ca, i) in cas" :key="i">
                <div class="pb-2">{{ ca.name }}</div>
                <ul v-for="(caTwo, j) in ca.caTwos" :key="j">
                    <li>
                        <div>{{ caTwo.name }}</div>
                        <ul v-for="(caThree, k) in caTwo.caThrees" :key="k">
                            <li>
                                <div class="row pb-2">
                                    <div class="col-10">
                                        {{ caThree.name }}
                                        <button type="button" class="btn btn-light p-0" v-if="caThree.id === 3" @click="showList('customer')"><i class="far fa-list-alt"></i></button>
                                        <button type="button" class="btn btn-light p-0" v-if="caThree.id === 4" @click="showList('supplier')"><i class="far fa-list-alt"></i></button>
                                    </div>
                                    <div class="col-2"><button type="button" class="btn btn-light btn-sm btn-block" @click="accountModalShow(caThree.id)"><i class="fas fa-plus font-size-12"></i></button></div>
                                </div>
                                <ul v-for="(account, l) in caThree.accounts" :key="l">
                                    <li>
                                        <div>{{ account.name }}</div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <ModalGeneral :title="accountModal.title" size="modal-lg" :showModal="accountModal.showModal" :hideModal="accountModalHide" v-if="accountModal.showModal">
            <div slot="modal-body">
                <account :ca_three_id="accountModal.caThreeId" v-if="accountModal.accountType === 'account'" :save-handler="saveHandler"></account>
                <customer v-if="accountModal.accountType === 'customer'" :save-handler="saveHandler"></customer>
                <supplier v-if="accountModal.accountType === 'supplier'" :save-handler="saveHandler"></supplier>
            </div>
        </ModalGeneral>
        <ModalGeneral :title="listModal.title" size="modal-lg" :showModal="listModal.showModal" :hideModal="listModalHide" v-if="listModal.showModal">
            <div slot="modal-body">
                <customers v-if="listModal.type === 'customer'" :show-additional-buttons="false" :show-action-buttons="false"></customers>
                <suppliers v-if="listModal.type === 'supplier'" :show-additional-buttons="false" :show-action-buttons="false"></suppliers>
            </div>
        </ModalGeneral>
    </section>
</template>

<script>
import {AccountService} from "@/services/account-service";
import Account from "@/components/app/account/Account";
import ModalGeneral from "@/components/app/dialog/ModalGeneral";
import Customer from "@/components/app/customer/customer";
import Supplier from "@/components/app/supplier/supplier";
import Customers from "@/components/app/customer/Customers";
import Suppliers from "@/components/app/supplier/Suppliers";
let accountService = new AccountService();

export default {
    name: "ChartOfAccounts",
    components: {Suppliers, Customers, Supplier, Customer, ModalGeneral, Account},
    data: function() {
        return {
            cas: [],
            accountModal:{
                title: 'Accounts',
                showModal: false,
                accountType: 'account',
                caThreeId: null,
            },
            listModal:{
                title: '',
                showModal: false,
                type: 'customer',
            },
        }
    },
    methods:{
        loadCaWithAccounts: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await accountService.loadCaWithAccounts();
            this.cas = response.cas;
            loader.hide();
        },
        showList(type){
            this.listModal.type = type;
            this.listModal.showModal = true;
            if(type === 'customer'){
                this.listModal.title = 'Customers';
            }
            if(type === 'supplier'){
                this.listModal.title = 'Suppliers';
            }
        },
        accountModalShow(caThreeId){
            this.accountModal.showModal = true;
            if(caThreeId === 3){
                this.accountModal.accountType = 'customer';
            }else if(caThreeId === 4){
                this.accountModal.accountType = 'supplier';
            }else {
                this.accountModal.accountType = 'account';
                this.accountModal.caThreeId = caThreeId;
            }
        },
        accountModalHide(){
            this.accountModal.showModal = false;
        },
        saveHandler: async function () {
            this.accountModal.showModal = false;
            await this.loadCaWithAccounts();
        },
        listModalHide(){
            this.listModal.showModal = false;
        },
    },
    async created() {
        await this.loadCaWithAccounts();
    }
}
</script>

<style scoped>
    .tree, .tree ul {
        margin:0;
        padding:0;
        list-style:none
    }
    .tree ul {
        margin-left:1em;
        position:relative
    }
    .tree ul ul {
        margin-left:.5em
    }
    .tree ul:before {
        content:"";
        display:block;
        width:0;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        border-left:1px solid
    }
    .tree li {
        margin:0;
        padding:0 2em;
        line-height:2em;
        color:#369;
        font-weight:700;
        position:relative
    }
    .tree ul li:before {
        content:"";
        display:block;
        width:20px;
        height:0;
        border-top:1px solid;
        margin-top:-1px;
        position:absolute;
        top:1em;
        left:0
    }
    .tree ul li:last-child:before {

        height:auto;
        top:1em;
        bottom:0
    }
    .indicator {
        margin-right:5px;
    }
    .tree li a {
        text-decoration: none;
        color:#369;
    }

</style>